import React from "react";
import Products from "../components/Products";

const ProductPage = () => {
  return (
    <main>
      <Products />
    </main>
  );
};

export default ProductPage;
