import React from 'react'
import Contactus from '../components/Contactus';

const ContactusPage = () => {
  return (
    <div>
      <Contactus/>
    </div>
  )
}

export default ContactusPage
